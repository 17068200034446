.header {
  .mobile-menu {
    width: 100% !important;
    .bm-menu {
      background: linear-gradient(
        rgba(33, 57, 103, 0.99) 80%,
        rgba(33, 57, 103, 0.7) 90%,
        rgba(87, 103, 134, 0.5) 100%
      );
    }
    hr {
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      margin: 0.5em 0 1em 0 !important;
      opacity: 1 !important;
    }
  }
}
.mobile-menu-title,
.mobile-menu-link a {
  text-transform: uppercase !important;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px 20px;
  color: white !important;
  line-height: 50px;
  font-weight: normal !important;
  letter-spacing: 0.2em;
}
.mobile-menu-link {
  div {
    transition: 0.2s ease all;
    &:hover {
      color: white !important;
    }
  }
  a {
    transition: 0.2s ease all;
    &:hover {
      border-radius: unset;
      // background: linear-gradient(
      //   rgba(33, 57, 103, 0.4) 50%,
      //   rgba(33, 57, 103, 0.4) 90%,
      //   rgba(33, 57, 103, 0.1) 100%
      // );
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}
.mobile-address-field a,
.contact-icon {
  cursor: pointer;
  transition: 0.2s ease all;
  &:hover {
    color: white !important;
  }
}
.mobile-menu-title {
  background-color: #f5b200;
}
.mobile-lang {
  padding: 0;
  a {
    padding: 5px 5px;
  }
}
.contact-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #213967;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0.5rem;
}
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 29px;
  height: 15px;
  right: 26px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background-color: #213967;
  transition: 0.2s ease all;
  position: relative;
  border-radius: 3px;
}

button {
  &:focus {
    border: none !important;
    outline: none !important;
  }
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  opacity: 0.9;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  right: 1.9rem !important;
  top: 1.3rem !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #f5b200;
  opacity: 1;
  height: 30px !important;
}

#react-burger-cross-btn {
  height: 40px !important;
  width: 40px !important;
  right: -15px !important;
  left: -2vw !important;
}
#react-burger-menu-btn {
  left: -1vh !important;
  top: -2.5vh !important;
  width: 50px !important;
  height: 50px !important;
}

/*
    Sidebar wrapper styles
    Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
    */
.bm-menu-wrap {
  position: fixed;
  left: 0;
  top: 0;
}

/* General sidebar styles */
.bm-menu {
  background-color: transparent;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  // height: 100vh !important;
  // width: 100vw;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0;
  width: 100%;
  .contact-icon {
    background-color: #f5b200;
    svg {
      path {
        color: white;
      }
    }
  }
}

/* Individual item */
.bm-item {
  display: inline-block;
  margin: 0% !important;
  display: inline-block;
  outline: none;
  text-align: center;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
}
