.consultant {
  .contact-icon {
    width: 40px;
    height: 40px;
  }
  .borders-on-sides {
    border-right: 0.1rem solid rgba(255, 255, 255, 0.3) !important;
    border-left: 0.1rem solid rgba(255, 255, 255, 0.3) !important;
  }
  p {
    margin: 0;
    color: #55290a;
    line-height: 1.8rem;
    font-size: 14px;
  }
  a {
    color: #55290a;
    line-height: 1.8rem;
    font-size: 14px;
  }
  svg {
    font-size: 18px !important;
    path {
      color: white;
    }
  }
  .img-wrapper {
    img {
      border-radius: 50%;
      border: 0.22em solid rgba(255, 255, 255, 0.9);
      width: 100%;
      max-width: 140px;
    }
  }
}
