@font-face {
  font-family: muse;
  src: url("./fonts/museosanscyrl/MuseoSansCyrl.ttf");
}
body,
html {
  background-color: #f5b200;
  font-family: muse;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .row {
    margin: 0;
  }
  a {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0.1;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
}
.scroll-lock {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.category-card-img {
  max-height: 12rem;
  object-fit: cover;
}
