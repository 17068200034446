.category-section {
  margin-top: 8rem !important;
  p.active {
    color: #55290a;
    text-decoration: underline;
  }

  p {
    transition: 0.5s ease all;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    transition: all 0.15s ease-in-out;
    font-size: 19px;
    letter-spacing: 1px;
  }
  .left {
    z-index: 1;
  }
  .middle {
    z-index: 2;
  }
  .right {
    z-index: 1;
  }
  .left p {
    transform: translateX(7rem);
  }
  .img-1 {
    transition: 0.5s ease all;
    transform: translateX(11rem);
  }
  .img-1.active {
    transform: translate(11rem, -4.5rem);
  }
  .img-2 {
    transition: 0.5s ease all;
    transform: translate(2.8rem, -1.5rem);
  }
  .img-2.active {
    transform: translate(2.8rem, -5.5rem);
  }
  .img-3,
  .right p {
    transition: 0.5s ease all;
    transform: translate(-8.5rem, 0rem);
  }
  .img-3.active {
    transform: translate(-8rem, -5rem);
  }
}

@media only screen and (max-width: 1700px) {
  .category-section {
    .img-3,
    .right p {
      transform: translate(-5.2rem, 0rem);
    }
    .img-3.active {
      transform: translate(-5rem, -5rem);
    }
    .img-2 {
      transform: translate(1rem, -1.5rem);
    }
    .img-2.active {
      transform: translate(1rem, -5.5rem);
    }
    .img-1 {
      transform: translateX(7rem);
    }
    .img-1.active {
      transform: translate(7rem, -4.5rem);
    }
    .left p {
      transform: translateX(5rem);
    }
  }
}
@media only screen and (max-width: 1160px) {
  .category-section {
    .img-3,
    .right p {
      transform: translate(-3.2rem, 0rem);
    }
    .img-3.active {
      transform: translate(-3.2rem, -3rem);
    }
    .img-2 {
      transform: translate(1rem, -1.5rem);
    }
    .img-2.active {
      transform: translate(1rem, -3.5rem);
    }
    .img-1 {
      transform: translateX(5rem);
    }
    .img-1.active {
      transform: translate(5rem, -2.5rem);
    }
    .left p {
      transform: translateX(5rem);
    }
  }
}
@media only screen and (max-width: 780px) {
  .category-section {
    .img-3 {
      transform: translate(-1.6rem, 0rem);
    }
    .img-2 {
      transform: translate(1rem, -1.5rem);
    }
    .img-1 {
      transform: translateX(3.6rem);
    }
    .middle p {
      transform: translateX(0.6rem);
    }
    .left p {
      transform: translateX(2rem);
    }
    .right p {
      transform: translateX(-0.3rem);
    }
  }
}
