.footer {
  background-color: #f5b200;
  .footer-wrap {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .footer-left {
    font-size: 1.1;
  }
  svg {
    path {
      color: white;
    }
  }
  hr {
    border: 0;
    border-top: 1px solid #eee;
    opacity: 0.5;
    width: 98%;
    margin: 0 auto;
  }
  h6 {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 5px;
    letter-spacing: 2px;
  }
  a {
    color: #55290a !important;
    text-transform: capitalize;
    transition: 0.2s all;
    &:hover {
      color: white !important;
    }
  }
  p {
    color: #55290a !important;
    margin: 0;
    opacity: 0.7;
    font-size: 15px !important;
    line-height: 24px !important;
    color: #55290a !important;
  }
}
