.product-page {
  .img-fluid {
    max-width: 500px;
    width: 100%;
    height: auto;
    position: sticky;
    top: 8rem;
  }
  h1 {
    text-transform: uppercase;
    color: #55290a;
    position: relative;
    font-weight: 600;
    font-size: 1.7em;
  }
  p {
    color: #55290a;
    text-align: justify;
  }
  .card {
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:hover {
      .card-text {
        opacity: 0.8;
      }
    }
    img {
      border-radius: 50%;
      border: solid 1px rgba(85, 85, 85, 0.3);
      width: 100%;
    }

    .card-body {
      padding: 0;
      .card-text {
        text-align: center;
        font-size: 0.8em;
        transition: 0.2s ease all;
      }
    }
  }
  .card.grass {
    img {
      border-radius: 0;
      height: auto;
      border: none !important;
    }
  }
}
