.category {
  padding-top: 8rem;
  //   padding-bottom: 2.875rem;
  background-color: #f5b200;
  min-height: 70vh;
  ul {
    list-style: none;
  }
  .hover-card {
    cursor: pointer;
    img {
      transition: 0.2s ease all;
    }
    &:hover {
      .card-title {
        background-color: rgba($color: #213967, $alpha: 0.9);
      }
      img {
        transform: scale(1.1);
      }
    }
  }
  .card {
    overflow: hidden;
    border-radius: 0 !important;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
    background-color: rgba($color: #000000, $alpha: 0.4);
    transition: 0.2s ease all;
    border: none !important;
    // &:hover {
    //   background-color: rgba($color: #000000, $alpha: 0.3) !important;
    // }
    img {
      transform: translateY(0rem);
      width: 100%;
      transition: 0.2s ease all !important;
    }
    .card-img-overlay,
    .card-img {
      border-radius: 0 !important;
    }

    .card-title {
      transition: 0.2s ease all;
      line-height: 1.6;
      text-align: center;
      background-color: rgba($color: #000000, $alpha: 0.5);
      padding: 0.5em 0.5em 0.5em 0.5em;
      font-weight: 100;
      font-size: 17px;
      letter-spacing: 0.9px;
      white-space: unset;
      text-transform: uppercase;
    }
  }
  .big-card {
    width: 100% !important;
    height: 100% !important;
    .card-title {
      background-color: transparent !important;
      color: white;
      font-size: 1.6em;
      text-transform: uppercase;
      text-align: center;
    }
  }
  .type-card {
    .card-title {
      font-size: 1.3em;
      width: 80%;
    }
  }
  .filter-container {
    background-color: rgba($color: #000000, $alpha: 0.3);
    width: 100%;
    .filter-item {
      height: 190px;
    }
  }
}
