.consultation-modal {
  svg {
    path {
      color: white;
    }
  }
  .modal-content {
    border: none !important;
    input:focus {
      outline: rgba(245, 178, 0, 0.2) !important;
      box-shadow: 0 1px 5px rgba(245, 178, 0, 0.5) !important;
      border-color: rgba(245, 178, 0, 0.2);
    }
  }
  .btn {
    border: 2px solid #f5b200;
    font-weight: 700;
    padding: 0 20px;
    transition: all 0.2s ease;
    background-color: #f5b200;
    color: #55290a;
    line-height: 39px;

    border-radius: 0.2em;
    &:hover {
      opacity: 0.8;
      background-color: #f5b200;
      border: 2px solid #f5b200;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
      color: #55290a;
    }
    &:focus {
      background-color: #f5b200 !important;
      border: 2px solid #f5b200 !important;
      outline: none !important;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5) !important;
      color: #55290a !important;
    }
  }
  .modal-header {
    border-bottom: none !important;
    margin-top: 0;
    h4 {
      color: #333;
      font-weight: 700;
      font-size: 26px;
    }
  }
  .modal-body {
    p {
      font-size: 16px;
      color: #444;
      margin-bottom: 25px;
    }
  }
}
.consultation-modal-button {
  font-size: 14px;
  background-color: #fdefcc !important;
  border-color: #fdefcc !important;
  padding: 0.5em !important;
  border-radius: 0.2em;
  border: none !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  color: #55290a !important;
  outline: none !important;
  transition: 0.3s;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5) !important;
  &:hover {
    background-color: white !important;
    border-color: white !important;
  }
  &:focus {
    background-color: #fdefcc !important;
    border-color: #fdefcc !important;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5) !important;
    outline: none !important;
    padding: 0.5em !important;
  }
}
.modal-backdrop {
  background-color: white !important;
}
.product-care-modal {
  .spinner-border {
    color: #213967 !important;
  }
}
.product-modal {
  .img-wrap {
    text-align: center;
  }
  img {
    object-fit: cover;
    width: -moz-fit-content;
    max-width: 100%;
    width: fit-content;
  }
  img.grass-img {
    max-width: 600px;
  }
  .spinner-border {
    color: #213967 !important;
  }
  .col p strong {
    letter-spacing: 0.7px;
  }
}
