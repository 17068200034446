.bc-yellow {
  background-color: #f5b200;
  //   background: linear-gradient(
  //     rgba(245, 178, 0, 1) 10%,
  //     rgba(245, 178, 0, 0.8) 90%,
  //     rgba(100, 133, 170, 0.7) 100%
  //   );
}

.btn:focus {
  box-shadow: none !important;
}
a:active:focus {
  background-color: rgba(#f5b200, 0.1);
}
.page-wrapper {
  background-color: #f5b200;
  min-height: 70vh;
  padding-top: 3rem;
}
.bg-op-brown {
  background-color: rgba(#55290a, 0.4);
}
.bg-warm-white {
  background-color: #fdefcc !important;
}
