.parquet-product {
  .parquet-nav {
    display: block;
    text-align: center;
    line-height: 50px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: white;
    background-color: rgba(33, 57, 103, 0.9);
    font-style: inherit !important;
    text-decoration: none;
    letter-spacing: 0.2em;
    font-weight: normal !important;
    transition: 0.2s ease all;
    text-transform: uppercase;
    font-size: 1.2rem;
    padding: 0 5px;
    &:hover {
      background-color: rgba(33, 57, 103, 1) !important;
    }
  }
  .parquet-nav.active {
    background-color: rgba(33, 57, 103, 1) !important;
  }
  .card {
    background-color: transparent;
    border-radius: 0;
    border: none;
    &:hover {
      cursor: pointer;
      .card-text {
        opacity: 0.8;
      }
    }
    img {
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5) !important;
      width: 100%;
    }
    .card-body {
      padding: 0 !important;
      .card-text {
        transition: 0.2s ease all;
        margin-top: 0.5rem;
      }
    }
  }
}

.parquet-bed {
  hr {
    border: 0;
    border-top: 1px solid #eee;
    opacity: 0.5;
    width: 98%;
    margin: 0 auto;
  }
  .parquet-category {
    .card {
      &:hover {
        .card-title {
          background-color: rgba($color: #000000, $alpha: 0.6) !important;
        }
      }
      background-color: rgba($color: #000000, $alpha: 0.4) !important;
      .card-title {
        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.6) !important;
        }
      }
    }
  }
  .img-wrap {
    position: relative;
    .nav-wrap {
      position: relative;
      z-index: 10 !important;
      transform: translateY(-5rem);
    }
    .parquet-nav {
      cursor: pointer;
      z-index: 4;
      img {
        transition: 0.2s ease all;
        border: 4px solid #f5b200;
        transition: border-color 0.15s ease-in-out;
        border-radius: 50%;
      }
    }
    .parquet-nav {
      transition: 0.2s ease all;
      max-width: 11.1%;
      img.active {
        border-color: black !important;
      }
      img {
        transition: 0.2s ease all;
        &:hover {
          border-color: black !important;
        }
      }
    }
    .floor-img {
      position: absolute;
      width: 100%;
      transform: translate(-1rem, 9rem);
      display: none;
    }
    .floor-img.active {
      transition: 0.2s ease all;
      display: block;
    }
    .bed {
      position: relative;
      z-index: 3;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1680px) {
  .floor-img {
    transform: translate(-1rem, 7rem) !important;
  }
}

@media only screen and (max-width: 1420px) {
  .floor-img {
    transform: translate(-1rem, 5rem) !important;
  }
  .nav-wrap {
    transform: translateY(-3.5rem) !important;
    img {
      border: 2px solid #f5b200 !important;
    }
  }
}

@media only screen and (max-width: 992px) {
  .floor-img {
    transform: translate(-1rem, 4rem) !important;
  }
}
@media only screen and (max-width: 900px) {
  .floor-img {
    transform: translate(-1rem, 5rem) !important;
  }
  .nav-wrap {
    transform: translateY(-2rem) !important;
  }
}

@media only screen and (max-width: 710px) {
  .floor-img {
    transform: translate(-1rem, 3rem) !important;
  }
  .nav-wrap {
    transform: translateY(-3rem) !important;
  }
}
