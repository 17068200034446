.clients-slider {
  min-height: 22rem;
  background: linear-gradient(181deg, #f5b200, #ffd669);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 56s ease infinite;
  -moz-animation: AnimationName 56s ease infinite;
  animation: AnimationName 56s ease infinite;

  @-webkit-keyframes AnimationName {
    0% {
      background-position: 0% 49%;
    }
    50% {
      background-position: 100% 52%;
    }
    100% {
      background-position: 0% 49%;
    }
  }
  @-moz-keyframes AnimationName {
    0% {
      background-position: 0% 49%;
    }
    50% {
      background-position: 100% 52%;
    }
    100% {
      background-position: 0% 49%;
    }
  }
  @keyframes AnimationName {
    0% {
      background-position: 0% 49%;
    }
    50% {
      background-position: 100% 52%;
    }
    100% {
      background-position: 0% 49%;
    }
  }
  .client-list-title {
    color: #55290a;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 48px;
    font-family: muse;
    padding: 0.1em;
  }
  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    display: none;
  }
  .alice-carousel__dots-item:not(.__custom) {
    background-color: white !important;
  }
  .alice-carousel__dots-item:not(.__custom).__active {
    background-color: #3f4395 !important;
  }
}

@media only screen and (max-width: 768px) {
  .client-list-title {
    font-size: 40px !important;
  }
}
@media only screen and (max-width: 375px) {
  .client-list-title {
    font-size: 30px !important;
  }
}
