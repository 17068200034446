.clients-section {
  min-height: 100vh;
  .card {
    background-color: transparent !important;
    border: none !important;
    margin: 1rem 3rem;
    img {
      transform: translateY(0.4rem);
    }
    .card-title {
      font-weight: 700;
      font-size: 15px;
      text-transform: uppercase;
      color: #fff;
      background-color: #3f4395;
      padding: 3px 6px;
      line-height: 1.3;
      white-space: nowrap;
      display: inline-block;
      vertical-align: top;
      font-family: muse;
      text-align: center;
    }
    transition: 0.3s ease all;
    &:hover {
      transform: translateY(-1rem);
    }
  }
  .client-title {
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 48px;
    font-family: muse;
    -webkit-box-shadow: -1px 18px 18px -23px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 18px 18px -23px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 18px 18px -23px rgba(0, 0, 0, 0.75);
    background-color: #3f4395;
    padding: 0.1em;
  }
  .first-row {
    padding-top: 4rem;
  }
}
// .card {
//   background-color: transparent !important;
//   border: none !important;
//   margin: 1rem 3rem;
//   img {
//     transform: translateY(0.4rem);
//   }
//   .card-title {
//     font-weight: 700;
//     font-size: 15px;
//     text-transform: uppercase;
//     color: #fff;
//     background-color: #3f4395;
//     padding: 3px 6px;
//     line-height: 1.3;
//     white-space: nowrap;
//     display: inline-block;
//     vertical-align: top;
//     font-family: muse;
//     text-align: center;
//   }
//   transition: 0.3s ease all;
//   &:hover {
//     transform: translateY(-1rem);
//   }
// }
.card.up {
  transform: translateY(-4em);
  transition: 0.3s ease all;
  &:hover {
    transform: translateY(-5rem);
  }
}
.card-wrap {
  background-image: url(../../../../images/client_section/pin.png);
  text-align: center;
  margin: 0 auto;
  height: 146px;
  background-repeat: no-repeat;
  background-position: center bottom 0;
  display: inline-block;
  vertical-align: top;
  padding: 0 10px;
}
.clients-section:before {
  content: "";
  display: block;
  width: 100%;
  min-height: 100vh;
  position: absolute;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),
    url(../../../../images/clients-back.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.alice-slider-card {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0;
}
.alice-carousel__dots {
  margin-top: .5rem !important;
}

@media only screen and (max-width: 1330px) {
  .clients-section {
    .card {
      margin: 1rem 2rem !important;
    }
  }
}
@media only screen and (max-width: 1127px) {
  .clients-section {
    .card {
      margin: 1rem 1rem !important;
    }
  }
}
@media only screen and (max-width: 935px) {
  .clients-section {
    .card {
      margin: 1rem 0.5rem !important;
    }
  }
}
@media only screen and (max-width: 840px) {
  .clients-section {
    .card {
      margin: 1rem 0rem !important;
    }
  }
}
