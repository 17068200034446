.tools {
  padding-top: 8rem;
  padding-bottom: 2.875rem;
  min-height: 60vh;
  background-color: #f5b200;
  .card {
    background-color: rgba($color: #000000, $alpha: 0.4) !important;
    border-radius: unset;
    transition: 0.2s ease all;
    border: none !important;
    .card-title {
      background-color: rgba($color: #000000, $alpha: 0.6);
      line-height: 1.6;
      padding: 0.5em 0.5em 0.5em 0.5em !important;
      width: 90%;
      font-weight: 500;
      font-size: 1.2rem;
      white-space: unset;
      color: white;
      text-transform: uppercase;
      text-align: center;
    }
    img {
      transform: translateY(0);
    }
    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.6) !important;
    }
  }
}
@media only screen and (max-width: 375px) {
  .tools {
    .card-title {
      font-size: 1rem !important;
    }
  }
}
