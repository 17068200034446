.header {
  .header-link {
    word-break: break-word;
    color: white !important;
    font-size: 1.4em !important;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
    transition: 0.3s ease all !important;
    font-weight: bold !important;
    &:hover {
      color: #55290a !important;
      border-bottom: 1px solid #55290a !important;
    }
  }
  .header-link.active {
    color: #55290a !important;
    border-bottom: 1px solid #55290a !important;
  }
}
