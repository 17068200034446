.contacts {
  padding-top: 10rem;
  padding-bottom: 2.875rem;
  min-height: 70vh;
  background-color: #f5b200;
  .text-light {
    color: #55290a !important;
  }
  i,
  a {
    background-color: #55290a;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: #213967;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0.5rem;
    margin-right: 0.5rem;
  }

  p {
    color: #55290a !important;
    font-size: 1.1em;
  }
  p.title {
    font-weight: 600;
    font-weight: bold;
    margin-right: 0.2em;
    line-height: 2rem;
  }
  svg {
    path {
      color: #fdefcc;
    }
  }
  .contacts-right {
    background-color: #fdefcc;
    border-radius: 1em;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
    min-height: 20rem;
    .times {
      p {
        margin: 0 0 0.5em 0;
        font-size: 1rem;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .contacts {
    padding-top: 6rem !important;
  }
}
