.header {
  left: 0;
  position: sticky;
  background-color: #f5b200;
  right: 0;
  top: 0;
  z-index: 999;
  transition: 0.2s ease all;
  img {
    max-width: 200px !important;
    width: 100%;
  }
}
.header.active {
  transition: 0.2s ease all;
  background: linear-gradient(
    rgba(199, 145, 0, 1) 30%,
    rgba(199, 145, 0, 0.8) 80%,
    rgba(199, 145, 0, 0) 100%
  );
}
