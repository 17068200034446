.care-products {
  .card-header {
    padding: 0.75rem 1rem !important;
  }
  .filter-item {
    .card {
      max-height: 25rem !important;
    }
  }
  .twenty-img {
    width: 100%;
  }
  .twenty-slider {
    position: relative;
    height: 100%;
    width: 2px;
    background: white;
    svg {
      cursor: pointer;
      color: white;
      position: absolute;
      top: 40%;
      transform: translateX(-16px);
      font-size: 35px;
      border-radius: 50%;
      border: 2px solid white;
    }
  }
  .filter-container {
    background-color: transparent !important;
    min-height: 400px;
    .filter-item {
      min-height: 400px !important;
    }
    .card {
      cursor: pointer;
      color: #2f3036 !important;
      background-color: rgba($color: #ffffff, $alpha: 0.5);
      box-shadow: none;
      border-radius: 5px !important;
      &:hover {
        button {
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) !important;
        }
      }
      img {
        // object-fit: contain;
        min-width: 100px;
        max-width: 130px;
      }
      .card-footer {
        width: 100%;

        button {
          color: #2f3036 !important;
          width: 100%;
          background-color: transparent;
          border: none;
          transition: 0.2s ease all;
          &:focus {
            box-shadow: none !important;
            outline: none !important;
            border: none !important;
          }
        }
      }
    }
  }
  .bg-brown {
    background-color: #2f3036 !important;
    &:hover {
      background-color: rgba(47, 48, 54, 0.8) !important;
    }
  }
  .bg-brown.active {
    background-color: rgba(47, 48, 54, 0.8) !important;
  }
  .isotope-button {
    background-color: #2f3036 !important;
    &:hover {
      background-color: rgba(47, 48, 54, 0.8) !important;
    }
  }
  .isotope-button.active {
    background-color: rgba(47, 48, 54, 0.8) !important;
  }
}
